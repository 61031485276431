import React, { Component } from "react";
import { StaticQuery } from "gatsby";
import { graphql } from "gatsby";
import { presidentSlug } from "../../utils/slugs";
import { lastnameImage, lastname } from "../../utils/formatters";
import styled from "styled-components";

const Img = styled.img`
  border-style: solid;
  border-width: 1px;
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  border-radius: 50%;
  cursor: pointer;

  &:hover {
    box-shadow: 0 0  7px #666;
  }
`;

/**
 * This create am image of a president with either a link to the president page
 * or a clickHandler. 
 */
class PresidentImageLink extends Component {

  /**
   * Get the image tag for a president image 
   * @param {} props 
   */
  presidentImage(props) {
    const president = props.president; 

    return (
    <Img
      src={`/assets/${lastnameImage(president.node.name)}.jpg`}
      className={`${president.node.party.toLowerCase()}-border`}
      size={props.size}
      onClick={() => {
        props.clickHandler &&
          props.clickHandler(president.node.name);
      }}
    ></Img>      
    )
  }

  /**
   * Render the image of the president and attach clickHandlers if passed into the tag 
   * @param {*} props 
   */
  renderImage(props) {
    const president = props.president; 

    if(props.clickHandler) {
      return this.presidentImage(props);         
    } else {
      return (
        <a href={"/presidents/" + presidentSlug(president.node.name)}>
          {this.presidentImage(props)}
        </a>
      ); 
    }
  }
 
  render() {
    const label = this.props.label !== false ? true : false;

    return (
      <StaticQuery
        query={graphql`
          {
            allPresidentSummary {
              edges {
                node {
                  id
                  presidentId
                  name
                  party
                }
              }
            }
          }
        `}
        render={data => {
          const president = data.allPresidentSummary.edges.find(item => {
            return (
              item.node.name === this.props.name ||
              item.node.presidentId === this.props.presidentId
            );
          });

          if (!president) {
            throw new Error(
              "President not found for the passed in prop.name: " + this.props.name,
              this.props.name,
              " or id: ",
              this.props.id
            );
          }

          const localProps = {
            size: this.props.size || 125,
            president: president, 
            clickHandler: this.props.clickHandler
          }; 

          return (
            <div>              
              { this.renderImage(localProps) }
              <div>{label ? lastname(president.node.name) : ""}</div>
            </div>
          );
        }}
      />
    );
  }
}

export default PresidentImageLink;
