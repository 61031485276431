import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql, navigate } from "gatsby"
import { Row, Col, Button } from "react-bootstrap"
import { SectionHeader } from "../common/section-header";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowAltCircleRight } from '@fortawesome/free-solid-svg-icons'
import learningStyles from "./learning.module.css"

import parse from 'html-react-parser'; 

class Learning extends React.Component {

  constructor(props) {
    super(props);

    this.css = this.props.css ? this.props.css : 'container';
    this.renderArticles = this.renderArticles.bind(this);
  }

  renderArticles(articles) {

    let firstArticle = articles[0];
    let secArticle = articles[1];
    let thirdArticle = articles[2];

    return (
      <section id="learning-center">
        <SectionHeader
          name={this.props.title ? this.props.title : "Learning Center"}
        />
        <Row className="container news">
          <Col>
            <div className={learningStyles.articleText + " " + ["intro"]}>
              <p>
                How do we come up with our numbers? How can you crunch the
                numbers yourself? What are the best indicators of an economy’s
                well-being and what might that information mean to you
                individually? It’s all in the Learning Center.
              </p>
            </div>
          </Col>
        </Row>
        <Row className="container news first-item">
          <Col>
            <label>
              Learning Center |{" "}
              {new Date(firstArticle.date).toISOString().slice(0, 10)}
            </label>
            <h2>
              <a href={firstArticle.path}>{parse(firstArticle.title)}</a>
            </h2>
            <div className="dek"> {parse(firstArticle.excerpt)} </div>
          </Col>
          <Col>
            {/* <div>
              { (firstArticle.acf.image) && <Img fluid={firstArticle.acf.image.localFile.childImageSharp.fluid} /> }                  
            </div>               */}
          </Col>
        </Row>
        <Row className="container news second-item">
          <Col>
            <label>
              News | {new Date(secArticle.date).toISOString().slice(0, 10)}
            </label>
            <h2>
              <a href={secArticle.path}>{parse(secArticle.title)}</a>
            </h2>
            {/* { (secArticle.acf.image) && <Img fluid={secArticle.acf.image.localFile.childImageSharp.fluid} style={{height: "250px", marginBottom: "5px"}} /> }  */}
            <div className="dek"> {parse(secArticle.excerpt)}</div>
          </Col>
          <Col>
            <label>
              Learning Center |{" "}
              {new Date(thirdArticle.date).toISOString().slice(0, 10)}
            </label>
            <h2>
              <a href={thirdArticle.path}>{parse(thirdArticle.title)}</a>
            </h2>
            {/* { (thirdArticle.acf.image) && <Img fluid={thirdArticle.acf.image.localFile.childImageSharp.fluid} style={{height: "250px", marginBottom: "5px"}} /> }  */}
            <div className="dek"> {parse(thirdArticle.excerpt)} </div>
          </Col>
        </Row>        
        <Row>          
          <Col className="more-button">
            <Button className={learningStyles.moreButton}
              onClick={ ()=> { navigate("/learning-center") } }
            >
              Visit Our Learning Center
              <FontAwesomeIcon
                width="0"
                icon={faArrowAltCircleRight}
                style={{
                  float: "right",
                  color: "#FF6700",
                  fontSize: "24px",
                  backgroundColor: "white",
                  borderRadius: "50px",
                }}
              />
            </Button>
          </Col>          
        </Row>
      </section>
    );
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
      {
        allWordpressPost(limit: 3, sort: {order: DESC, fields: date}, filter: {categories: {elemMatch: {name: {eq: "Learning Center"}}}}) {
          nodes {
            content
            excerpt
            categories {
                name
            }            
            date
            path
            slug
            title
            id
          }
        }
      }
    `}
        render={(data) => {
          const result = data.allWordpressPost.nodes;

          if (!result) {
            console.error(`Could not find articles for this: ${this.props.name} was not found in graphql query for section, content could not be displayed.`);
            return (
              <div />
            );
          } else {
            return (this.renderArticles(result))
          }
        }}
      />
    );
  }

};

Learning.propTypes = {
  css: PropTypes.string
};

export { Learning }; 