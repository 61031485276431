
/**
 * Get the name of the president. From the president full name
 * @param {string} president 
 * @throws Exception
 * @returns {string}
 */
export function lastname(president) {
  if(!president) {
    throw new Error('component:PresidentPage - could not look up president lastname');
  }
  let lastName = president.split(" ").pop(); 
  return lastName; 
}

/**
 * President lastnames are used to defined the background image to use except for bush's which uses
 * numeric index after the lastname based on order of presidency. 
 * @param {string} president 
 * @returns {string} 
 */
export function lastnameImage(president) {
  if(!president) {
    throw new Error('component:PresidentPage - could not look up president lastname');
  }
  let lastName = president.split(" ").pop(); 
  if(lastName === 'Bush') {            
    if(president.split(" ")[1] === 'H.W.') {       
      lastName = 'bush1'; 
    } else {
      lastName = 'bush2'; 
    }
  }
  return lastName.toLowerCase(); 
}

/**
 * Return a precision 2 formatted percent string for rendering. 
 * @param {float} number 
 * @returns {string}
 */
export function percent(number) {
  if(!number) {
    return 'N/A'
  }

  return `${(number * 100).toFixed(2)}%`; 
}

/**
 * Returns a different number between to values for rendering
 * @param {float} number1 
 * @param {float} number2 
 * @returns string
 */
export function difference(number1, number2, showSign = false) {
  if(!number1 || !number2) {
    return 'N/A';
  }


  let plus = (showSign && (number1 - number2) > 0) ? '+' : ''; 
  return `${plus}${percent(number1 - number2)}`;
}

export function metricName(metric) {
  let prefix = metric.split('_')[0];

  switch(prefix) {
    case 'gdp': 
      return 'GDP'; 
    case 'dow': 
      return 'DOW Jones Index'; 
    case 'sandp': 
      return 'S & P 500 Index'; 
    case 'nasdaq': 
      return 'NASDAQ Index'; 
    case 'payroll': 
      return 'Job Growth'; 
    default: 
      return false; 

  }
}


export function getHighlight(number1, number2) {
  
}