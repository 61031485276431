import React, { Component } from "react"
import { Row, Col, Button } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowAltCircleRight, faUsersCog, faTruck, faLongArrowAltUp, faLongArrowAltDown } from '@fortawesome/free-solid-svg-icons'
import metricsStyles from "./metrics.module.css"
import { SectionHeader } from "../common/section-header.js"
import { faChartBar } from '@fortawesome/free-regular-svg-icons'
import  moment from "moment"; 

import PresidentImageLink from "../common/PresidentImageLink";

class Metrics extends Component {
    constructor(props) {
        super(props); 
    
        this.metrics = (this.props.metrics) ? this.props.metrics : [{}];  
        this.getMetricPercent = this.getMetricPercent.bind(this); 
        this.getLastUpdate = this.getLastUpdate.bind(this);
        this.getArrow = this.getArrow.bind(this);
    }

    getMetricPercent(name) {
        let metric = this.metrics.filter( obj => {
            return obj.name === name; 
        }); 

        if(!metric.length) {            
            console.error(`Component: Metrics - The metric name specified could not be found: ${name} check your 'Metrics' component params`); 
            return 'NaN'; 
        }

        return (metric[0].percentGain * 100).toFixed(1);
    }

    getLastUpdate(name) {
        let metric = this.metrics.filter( obj => {
            return obj.name === name; 
        }); 

        if(!metric.length) {            
            console.error(`Component: Metrics - The metric name specified could not be found: ${name} check your 'Metrics' component params`); 
            return 'Not Available'; 
        }

        if(name === 'gdp') {
            let date = moment(metric[0].date).utcOffset(6);            
            return `Quarter ${date.quarter()} of ${date.year()}`;

            // let month = date.getMonth() + 1;
            
        }

        if(name === 'payroll') {
            let date = moment(metric[0].date).utcOffset(6);                        
            date.toLocaleString('default', { month: 'long' });
            return `${date.format('MMM of YYYY')}`;
        }

        return moment(metric[0].date).utc().format('MM/DD/YYYY');         
    }

    getArrow(metricValue) {
        if(metricValue < 0) {
            return (<FontAwesomeIcon width="0" icon={faLongArrowAltDown} className="arrowDown" style={{ fontSize: "18px" }} />)
        } else {
            return (<FontAwesomeIcon width="0" icon={faLongArrowAltUp} className="arrowUp" style={{ fontSize: "18px" }} />)
        }
    }
    
    render() {
        console.log('current pres', process.env.GATSBY_CURRENT_PRESIDENT); 
        return (

            <div>
                <SectionHeader name="Metrics" />
                <Row>
                    <Col lg={6} className={'first-item'}>
                        <div className={metricsStyles.metricWrapper}>
                            <div className={metricsStyles.metricHeader}><FontAwesomeIcon width="0" icon={faChartBar} style={{ color: "#FF6700", fontSize: "24px", marginRight: "10px" }} /> Stock Market Performance By President</div>
                            <div className={metricsStyles.presidentGroup}>                                
                                <PresidentImageLink 
                                    presidentId={`${process.env.GATSBY_CURRENT_PRESIDENT}`} 
                                    size={100}
                                    label={false}
                                />                                
                            </div>

                            <div style={{ height: "33px", display: "flex", margin: "5px 0px", borderBottom: "1px solid #e1e1e1", lineHeight: "33px", fontSize: "16px" }}>
                                <div style={{ display: "inline-block", width: "30%" }}>S&P</div>
                                <div style={{ display: "inline-block", width: "30%", fontSize: "13px" }}>cuml. returns</div>
                                <div style={{ display: "inline-block", width: "40%", textAlign: "right" }}>
                                    { this.getMetricPercent('sandp') }% { this.getArrow(this.getMetricPercent('sandp')) }
                                </div>
                            </div>

                            <div style={{ height: "33px", display: "flex", margin: "5px 0px", borderBottom: "1px solid #e1e1e1", lineHeight: "33px", fontSize: "16px" }}>
                                <div style={{ display: "inline-block", width: "30%" }}>Nasdaq</div>
                                <div style={{ display: "inline-block", width: "30%", fontSize: "13px" }}>cuml. returns</div>
                                <div style={{ display: "inline-block", width: "40%", textAlign: "right" }}>
                                { this.getMetricPercent('nasdaq') }% { this.getArrow(this.getMetricPercent('nasdaq')) } 
                                </div>
                            </div>

                            <div style={{ height: "33px", display: "flex", margin: "5px 0px", borderBottom: "1px solid #e1e1e1", lineHeight: "33px", fontSize: "16px" }}>
                                <div style={{ display: "inline-block", width: "30%" }}>Dow</div>
                                <div style={{ display: "inline-block", width: "30%", fontSize: "13px" }}>cuml. returns</div>
                                <div style={{ display: "inline-block", width: "40%", textAlign: "right" }}>
                                { this.getMetricPercent('dow') }% { this.getArrow(this.getMetricPercent('dow')) } 
                                </div>
                            </div>

                            <div style={{ fontFamily: "'Lato', sans-serif", clear: "both", marginTop: "20px", color: "#aaa" }}>Last Updated: { this.getLastUpdate('dow') }</div>
                            <div style={{ fontFamily: "'Lato', sans-serif", clear: "both", marginBottom: "27px", color: "#777", height: "60px" }}>We track the S&P, Dow Jones and the NASDAQ by president and update our data after market close daily</div>
                            <div style={{ textAlign: "center" }}><a href="/metrics/stock-market-performance"><Button className={metricsStyles.moreButton}>Latest Stock Market Performance By President<FontAwesomeIcon width="0" icon={faArrowAltCircleRight} style={{ float: "right", color: "#FF6700", fontSize: "24px", backgroundColor: "white", borderRadius: "50px" }} /></Button></a></div>
                        </div>
                    </Col>

                    <Col lg={6} className={'second-item'}>
                        <div className={metricsStyles.metricWrapper}>
                            <div className={metricsStyles.metricHeader}>
                                <FontAwesomeIcon icon={faUsersCog} width="0" style={{ color: "#FF6700", fontSize: "24px", marginRight: "10px" }} /> Jobs Creation Growth By President</div>
                            <div className={metricsStyles.presidentGroup}>
                                <PresidentImageLink 
                                    presidentId={`${process.env.GATSBY_CURRENT_PRESIDENT}`} 
                                    size={100}
                                    label={false}
                                /> 
                            </div>

                            <div style={{ height: "33px", display: "flex", margin: "5px 0px", borderBottom: "1px solid #e1e1e1", lineHeight: "33px", fontSize: "16px" }}>
                                <div style={{ display: "inline-block", width: "50%" }}>Payrolls growth</div>
                                <div style={{ display: "inline-block", width: "50%", textAlign: "right" }}>
                                { this.getMetricPercent('payroll') }% { this.getArrow(this.getMetricPercent('payroll')) } 
                                </div>
                            </div>

                            <div style={{ fontFamily: "'Lato', sans-serif", clear: "both", marginTop: "20px", color: "#aaa" }}>Last Jobs Report: { this.getLastUpdate('payroll') }</div>
                            <div style={{ fontFamily: "'Lato', sans-serif", clear: "both", marginBottom: "27px", color: "#777", height: "60px" }}>Get the bottom line on jobs growth by president starting from 1945.</div>
                            <div style={{ textAlign: "center" }}><a href="/metrics/jobs-growth"><Button className={metricsStyles.moreButton}>Latest Jobs By President<FontAwesomeIcon width="0" icon={faArrowAltCircleRight} style={{ float: "right", color: "#FF6700", fontSize: "24px", backgroundColor: "white", borderRadius: "50px" }} /></Button></a></div>
                        </div>
                    </Col>

                    <Col lg={6} className={'third-item'}>
                        <div className={metricsStyles.metricWrapper}>
                            <div className={metricsStyles.metricHeader}>
                                <FontAwesomeIcon icon={faTruck} width="0" style={{ color: "#FF6700", fontSize: "24px", marginRight: "10px" }} /> GDP Growth By President</div>
                            <div className={metricsStyles.presidentGroup}>
                                <PresidentImageLink 
                                    presidentId={`${process.env.GATSBY_CURRENT_PRESIDENT}`} 
                                    size={100}
                                    label={false}
                                /> 
                            </div>

                            <div style={{ height: "33px", display: "flex", margin: "5px 0px", borderBottom: "1px solid #e1e1e1", lineHeight: "33px", fontSize: "16px" }}>
                                <div style={{ display: "inline-block", width: "30%" }}>GDP</div>
                                <div style={{ display: "inline-block", width: "30%", fontSize: "13px" }}>cuml. returns</div>
                                <div style={{ display: "inline-block", width: "40%", textAlign: "right" }}>
                                    { this.getMetricPercent('gdp') }% { this.getArrow(this.getMetricPercent('gdp')) }                                    
                                </div>
                            </div>

                            <div style={{ fontFamily: "'Lato', sans-serif", clear: "both", marginTop: "20px", color: "#aaa" }}>Last GDP Report: { this.getLastUpdate('gdp') }</div>
                            <div style={{ fontFamily: "'Lato', sans-serif", clear: "both", marginBottom: "27px", color: "#777", height: "60px" }}>We track GDP from every quarter starting from 1961.</div>
                            <div style={{ textAlign: "center" }}><a href="/metrics/gdp-growth"><Button className={metricsStyles.moreButton}>Latest GDP By President<FontAwesomeIcon width="0" icon={faArrowAltCircleRight} style={{ float: "right", color: "#FF6700", fontSize: "24px", backgroundColor: "white", borderRadius: "50px" }} /></Button></a></div>
                        </div>
                    </Col>
                    <Col lg={6} className={'fourth-item'}>
                        <div className={metricsStyles.metricWrapper} style={{marginLeft: "25px"}}>
                            <div className={metricsStyles.metricHeader}>
                                More metrics coming soon!
                            </div>

                            <ul>
                                <li style={{marginBottom: "0xp"}}>Trade deficit</li>
                                <li style={{marginBottom: "0xp"}}>Total debt</li>
                                <li style={{marginBottom: "0xp"}}>Hourly wages</li>
                                <li style={{marginBottom: "0xp"}}>Inflation</li>
                                <li style={{marginBottom: "0xp"}}>Federal deficit</li>
                                <li style={{marginBottom: "0xp"}}>Unemployment rate</li>

                            </ul>

                        </div>
                    </Col>


                </Row>


            </div>


        )
    }




}

export default Metrics; 